@import 'index-mixins';

$animationDuration: 200ms;

.OffersSlider {
  overflow-x: hidden;
  padding-bottom: 8px;

  &.OffersSlider--negativeOffsetTop {
    // Дабы перебить вес IndexPage_block.
    // IndexPage_block почему-то ниже чем этот селектор (может быть порядок инлайн стилей не совсем корректный)
    // Подумал что лучше добавить явный !important, чем играться с увелечением веса селектора.
    // Надо посмотреть порядок стилей
    margin-top: -62px !important;
  }

  @include maxTablet {
    &.OffersSlider--negativeOffsetTop {
      margin-top: -72px !important;
    }
  }

  @include maxMobile {
    &.OffersSlider--negativeOffsetTop {
      margin-top: 32px !important;
    }
  }
}

.gridSpreader {
  position: relative;
}

.container {
  height: 340px;
  white-space: nowrap;
  transition: transform $animationDuration;

  &--isSwiping {
    transition: transform calc($animationDuration * 2);
  }

  @include maxMobile {
    height: 224px;
  }
}

.row {
  flex-wrap: nowrap !important;
  height: 340px;

  @include maxMobile {
    height: auto;
  }
}

.nextButton,
.prevButton {
  width: $unit * 12;
  height: $unit * 12;
  padding: $unit * 3;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  margin-top: -$unit * 6;
  cursor: pointer;
  user-select: none;
  transition: opacity $animationDuration;

  &:hover {
    background-color: #e6e6e6; // white + rgba(0, 0, 0, 0.1);
  }

  &--isHidden {
    opacity: 0;
    pointer-events: none;
  }

  @include maxMobile {
    display: none;
  }
}

.nextButton {
  right: 0;
  margin-right: -$unit * 8;
}

.prevButton {
  left: 0;
  margin-left: -$unit * 8;
}

.dotsContainer {
  text-align: center;
  margin: $unit * 4 0;

  &--isHidden {
    display: none;
  }

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #212121;
    padding: 0;
    border: 0;
    font-size: 0;
    outline: none;
    cursor: pointer;
    opacity: 0.2;
    transition: opacity $animationDuration;
    display: inline-block;

    &--isActive {
      opacity: 1;
    }
  }

  .dot + .dot {
    margin-left: $unit * 2;
  }
}

.offer {
  display: inline-flex;
  vertical-align: top;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  @include card-shadow;

  &:last-child {
    margin-right: 0;
  }

  &Container {
    height: 340px;
    @include maxMobile {
      height: 224px;
    }
  }

  &--mock {
    flex-direction: column;
    background-color: #ffffff;
  }
}

.mock {
  position: relative;
  &HeaderPart {
    width: 100%;
    height: 35%;
    box-sizing: border-box;
    padding: 16px;

    @include maxMobile {
      position: absolute;
      top: 16px;
      right: 16px;
      height: 0px;
      padding: 0px;
    }
  }

  &ContentPart {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 65%;
    box-sizing: border-box;
    padding: 16px;

    @include maxMobile {
      height: 100%;
    }
  }

  &TextContent {
    padding-right: 44px;
  }

  &Icon {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    margin: 16px 0 80px 16px;

    @include maxMobile {
      margin-left: auto;
      height: 36px;
      width: 36px;
    }
  }

  &Action {
    height: 36px;
    width: 100%;
    margin-top: 32px;
    border-radius: 4px;
  }

  &Title {
    height: 32px;
    width: 100%;

    @include maxMobile {
      height: 21px;
    }
  }

  &DescriptionLong,
  &DescriptionShort {
    height: 18px;
  }
  &DescriptionLong {
    width: 100%;
    margin-top: 16px;
  }

  &DescriptionShort {
    width: 65%;
    margin-top: 4px;
  }

  &Gradient {
    will-change: opacity;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: ease-in-out;
    background: #f6f7f8;
    position: relative;
  }
}

// копипаста, может нужно изменить значения
@keyframes placeHolderShimmer {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.4;
  }
}
