@import 'index-mixins';

.wrapper {
  min-height: 320px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 40px;
  border-radius: 24px;
  background: $colorWhite;
  overflow: hidden;

  @include maxTablet {
    display: flex;
    flex-direction: column-reverse;
    padding: 24px;
  }
}

.phone,
.gradient {
  position: absolute;

  @include maxTablet {
    display: none;
  }
}

.phone {
  width: 264px;
  top: 40px;
  right: 98px;
  z-index: 1;
}

.gradient {
  width: 424px;
  right: 0;
  bottom: 0;
  border-radius: 0 0 0 24px;
  z-index: 0;
}

.content > form {
  margin: 0;
}

.title {
  font-size: 28px;
  line-height: 36px;
  font-weight: bold;
  margin-bottom: 16px;

  @include maxTablet {
    font-size: 20px;
    line-height: 26px;
  }
}

.description {
  @include font-subtitle;
  color: $colorBlack;
  margin-bottom: 16px;
  padding-right: 44px;
  margin-bottom: 38px;

  @include maxTablet {
    @include font-large;
    padding-right: 12px;
    margin-bottom: 86px;
  }

  @include maxMobile {
    color: $colorGreyMedium;
  }
}

.iconsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.note {
  position: absolute;
  bottom: 36px;
  @include font-small;
  color: $colorGreyMedium;
}

.storesIcons {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 85px;

  @include maxTablet {
    gap: 0;
    margin: 0;
  }
}

.storeIcon {
  @include maxTablet {
    width: 34px;
    height: 34px;
  }
}

.image {
  @include maxTablet {
    width: calc(100% + 48px);
    margin: -24px -24px 0 -24px;
    border-radius: 24px 24px 0 0;
    margin-bottom: 24px;
    object-fit: cover;
  }
}

.downloadWrapper {
  display: flex;
  align-items: center;
}

.downloadButton {
  @include font-base;
  font-weight: bold;
  background-color: $colorGreyDark;
  border-color: $colorGreyDark;
  color: $colorWhite;
  border-radius: 8px;
  padding: 0 54px;
  margin-right: 10px;

  &:hover {
    background-color: $colorGreyLight;
    border-color: $colorGreyLight;
    color: $colorBlack;
  }

  @include maxTablet {
    padding: 0 35px;
  }
}

.qrCodeWrapper {
  display: flex;
  align-items: center;
  margin-left: 12px;
  z-index: 1;
}
