@mixin small-shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

@mixin medium-shadow {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
}

@mixin big-shadow {
  box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.1);
}

@mixin large-shadow {
  box-shadow: 0 16px 36px 0 rgba(0, 0, 0, 0.2);
}

@mixin card-shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 0 1px 0 rgba(0, 0, 0, 0.2);
}
