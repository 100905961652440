@import 'mixins';

.container {
  width: calc(100% - 40px * 2);
  max-width: var(--grid-container-desktop-max-width);

  margin-left: auto;
  margin-right: auto;

  @include maxMobile() {
    max-width: var(--grid-container-mobile-max-width);
  }
}
