@import 'mixins';

.emptyList {
  margin-bottom: 64px;
}

.widget {
  margin: 64px 0 48px;

  @include maxMobile {
    margin-top: 48px;
  }
}

.title {
  margin: 64px 0 48px;
  @include font-title-2;
  font-weight: bold;
}
