@import 'index-mixins';

.SurchargePage {
  position: relative;
  background-color: $colorGreySmoke;

  padding-bottom: 56px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: 100vh;
}

.header {
  margin-bottom: 36px;

  @include maxMobile {
    margin-bottom: 20px;
  }
}

.content {
  max-width: 344px;
  padding: 0 16px;
  margin: auto;
  margin-bottom: 32px;
}

.modalError {
  .title {
    @include font-title-3;
    color: $colorBlack;
    font-weight: 700;
    margin: 24px 0 16px 0;
  }

  .reasonsHeader,
  .description,
  .hint,
  .reasons {
    color: $colorGreyMedium;
    @include font-base;
  }

  .reasons {
    text-align: left;
  }
}
