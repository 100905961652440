@import 'index-mixins';

.NavDropdown {
  display: flex;
  align-items: center;

  cursor: pointer;
  position: relative;
  z-index: 1;
}

.overlay {
  position: absolute;

  max-width: 350px;
  padding: $unit * 4 0;
  background: #fff;
  border-radius: $unit;
  margin: $unit * 4 0 0;

  @include big-shadow();
  z-index: 2;
}

.trigger {
  @include font-small;
  font-weight: 400;
  white-space: nowrap;
  color: #fff;
  opacity: 1;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.6;
  }
}

.trigger-icon {
  margin: 0 0 0 8px;
  transform: rotate(0);
  transition: transform 0.2s ease;

  &--flip {
    transform: rotate(180deg);
  }
}
