@import 'index-mixins';
@import 'index-variables';

.wide-container {
  width: 100%;
}

.container {
  position: absolute;
  width: 100%;
  font-size: 13px;
  text-align: left;
  padding: 16px 0;
  color: #fff;
  font-weight: 300;
  z-index: 1;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: calc(100% - #{$gutterWidth} * 4);
}

.logoWrapper {
  padding-right: 8px;
  display: flex;
  align-items: center;
  gap: 22px;
}

.wildCashbackLink {
  position: relative;
  text-decoration: none;
}

.wildCashbackLinkDisabled {
  pointer-events: none;
  cursor: default;
}

.wildCashback {
  position: relative;
  max-width: 190px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 6px 6px 6px 28px;
  border-radius: 16px;
  overflow: hidden;

  color: $colorGreenDarkWildCashback;
  background: $colorGreenLightWildCashback;
  backdrop-filter: blur(10px);

  font-family: 'Montserrat', sans-serif;
  font-size: 10px;
  line-height: 1.2;
  font-weight: 700;
}

.wildCashbackIcon {
  position: absolute;
  right: -18px;
  top: -18px;
  width: 102px;
  height: 100px;
  color: $colorGreenWildCashback;
}

.wildCashbackText {
  max-width: 92px;
}

.wildCashbackPercentWrapper {
  position: relative;
}

.wildCashbackPercent {
  font-size: 26px;
  line-height: 1.15;
}

.wildCashbackHanger {
  position: absolute;
  left: -38px;
  top: -12px;
  width: 70px;
  height: 70px;
  object-fit: contain;
}

.nav {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.logoSeparator {
  width: 1px;
  height: 48px;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 0 0 0 8px;
}

.logo {
  margin: 0 24px 0 0;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.6;
  }

  &--partner {
    padding: 0 0 0 24px;
    img {
      max-height: 48px;
    }
  }

  &-image {
    max-height: 48px;
  }

  &-wrapper {
    display: flex;
  }
}

.auth {
  position: relative;
}

.mobileAppLink {
  opacity: 1;
  margin: -4px 4px 0 0;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }

  &:last-of-type {
    margin: 0 20px 0 0;
  }
}
