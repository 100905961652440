@import 'mixins';

.seoOptions {
  padding: 16px 0;
}
.block {
  @include shadow-small;
  background-color: $colorWhite;
  padding: 16px;
  margin: 8px 0;
  min-height: 96px;
  position: relative;
  border-radius: 4px;
}
.caption {
  display: flex;
  justify-content: space-between;
}
.title {
  @include font-subtitle;
  margin: 0;
}
.link {
  @include font-small;
  color: $colorBlack;
  position: absolute;
  left: 16px;
  bottom: 16px;
  text-decoration: none;
  line-height: 2;
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.5;
  }
}
.arrow {
  margin-bottom: -4px;
}
.icon {
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: top;
  width: 48px;
  height: 48px;
}

.seoCabinet {
  background-image: url('https://static.onetwotrip.com/images/index/cabinet.svg');
}

.seoApp {
  background-image: url('https://static.onetwotrip.com/images/index/app.svg');
}
