@import 'index-mixins';

.Header,
.HeaderPartner {
  height: 72px;
  width: 100%;
}

.Header {
  background-color: $colorGreyDark;
  display: flex;
  align-items: center;
  justify-content: center;
}

.HeaderPartner {
  background-color: $colorWhite;

  .divider {
    width: 1px;
    height: 48px;
    margin: 0 16px;
    background-color: $colorGrey;
  }

  .header {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 1280px;
    margin: auto;
    padding: 0 32px;

    @include maxMobile {
      padding: 0 16px;
    }
  }

  .logo {
    height: 48px;
    pointer-events: none;
    user-select: none;
  }
}
