.infoPopup {
  text-align: left;

  &-title {
  }

  &-body {
    white-space: pre-wrap;
  }

  &-link {
    display: block;
    margin: 24px 0 0;
    color: #01abfb;
    text-decoration: none;
  }
}
