@import 'index-mixins';

.title {
  font-size: 28px;
  line-height: 32px;
  font-weight: bold;
  padding-top: 25px;
  margin: 0 0 8px;

  @include maxMobile {
    margin-bottom: 12px;
    padding-right: 45px;
  }
}

.description {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 36px;
}

.cardsWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;

  @include maxTablet {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
}

.card {
  padding: 24px;
  background-color: $colorWhite;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 90%;
  border-radius: 24px;

  @include maxTablet {
    background-size: 100%;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  @include maxMobile {
    background-size: 85%;
  }
}

.cardIcon {
  width: 88px;
  height: 88px;
  margin-bottom: 32px;
}

.cardTitle {
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  margin-bottom: 12px;
  padding-right: 22px;
}

.cardDescription {
  @include font-large;
  color: $colorGreyMedium;
  margin-bottom: 24px;
}

.cardButton {
  @include font-base;
  font-weight: bold;
  background-color: $colorGreyLight;
  border-color: $colorGreyLight;
  color: $colorBlack;
  border-radius: 8px;
  padding: 13px 16px;

  &:hover {
    background-color: $colorBlack;
    border-color: $colorBlack;
    color: $colorWhite;
  }
}
