@import 'index-mixins';

.BulletedListItem {
  display: flex;

  &.behavior--appLink {
    cursor: pointer;
  }

  @include maxTablet {
    & + & {
      margin-top: 18px;
    }

    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  @include maxMobile {
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
    margin: 0 0 24px 0;
  }
}

.icon {
  flex: 0 0 68px;

  margin: 0 8px 0 0;

  @include maxTablet {
    margin: 0 0 12px 0;
  }

  @include maxMobile {
    margin: 0 12px 0 0;
  }
}

.title {
  font-size: 16px;
  font-weight: 400;
  padding: 4px 0 4px 0;
  line-height: 20px;
  color: #212121;
  margin: 0;
  white-space: pre-line;
}

.text {
  @include font-small();
  padding: 4px 0 4px 0;

  color: #888888;
  white-space: pre-line;
}

.innerAppLink {
  @include font-small();
}
