@import 'src/styles/index-mixins';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  padding: 8px 12px;
  border-radius: 24px;
  background-color: $colorBlack;
  color: $colorWhite;

  &.containerWhite {
    background-color: $colorWhite;
    color: $colorBlack;
  }
}

.value {
  @include font-base;
  font-weight: 700;
}

.icon {
  max-width: 14px;
  max-height: 14px;
  object-fit: cover;
}
