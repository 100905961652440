@import 'index-mixins';

.mockCardsContainer {
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: center;
}

.offer {
  display: inline-flex;
  vertical-align: top;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 16px;
  @include card-shadow;

  &:last-child {
    margin-right: 0;
  }

  &Container {
    height: 384px;
    width: 282px;
    @include maxMobile {
      height: 314px;
      width: 231px;
    }
  }

  &--mock {
    position: relative;
    flex-direction: column;
    background-color: $colorWhite;
  }
}

.mock {
  position: relative;
  &HeaderPart {
    width: 100%;
    height: 35%;
    box-sizing: border-box;
    padding: 16px;
  }

  &ContentPart {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 65%;
    box-sizing: border-box;
    padding: 16px;

    @include maxMobile {
      height: 100%;
    }
  }

  &TextContent {
    padding-right: 44px;
  }

  &Icon {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    margin: 16px 0 80px 16px;

    @include maxMobile {
      margin-left: auto;
      height: 36px;
      width: 36px;
    }
  }

  &Action {
    height: 36px;
    width: 100%;
    margin-top: 32px;
    border-radius: 4px;
  }

  &Title {
    height: 32px;
    width: 100%;

    @include maxMobile {
      height: 21px;
    }
  }

  &DescriptionLong {
    width: 100%;
    margin-top: 16px;
    height: 18px;
  }

  &DescriptionShort {
    width: 65%;
    margin-top: 4px;
    height: 18px;
  }

  &Gradient {
    @include loadingSkeleton;
  }
}
