@import 'index-mixins';

$screenWithButtons: 1280px;

.offersBlock {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 60px 0 100px;

  @include mobile {
    padding: 24px 0 64px;
    margin: 0;
  }
}

.slider {
  margin: 0 auto;
  position: relative;
  max-width: $screenWithButtons;
  width: 100%;

  @include min-screen($screenWithButtons) {
    max-width: 1200px;
  }
}

.cardsViewport {
  overflow: hidden;
}

.cardsContainer {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  gap: 24px;
  padding: 0;
  margin: 0;
  list-style: none;

  @include mobile {
    gap: 16px;
  }
}

.cardsItem {
  &:first-child {
    @include max-screen($screenWithButtons - 1) {
      margin-left: 40px;
    }

    @include mobile {
      margin-left: max(calc((100% - 688px) / 2), 16px);
    }
  }

  &:last-child {
    @include max-screen($screenWithButtons - 1) {
      margin-right: 40px;
    }

    @include mobile {
      margin-right: max(calc((100% - 688px) / 2), 16px);
    }
  }
}

.dotsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;

  @include min-screen($screenWithButtons) {
    display: none;
  }
}

.dot {
  border: none;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: $colorBlack;
  padding: 0;
  opacity: 0.1;

  &.activeDot {
    opacity: 1;
  }
}
