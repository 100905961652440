@import "index-mixins";

.BulletedListItemIcon {
  height: $unit * 17;
  width: $unit * 17;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  box-sizing: border-box;
}
