@import 'mixins';

.ToursPriceCalendarWrapper {
}

.title {
  margin: 0 0 24px;
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  text-align: left;
  color: #212121;

  @include maxMobile {
    font-size: 21px;
    line-height: 28px;
  }

  &--isCentered {
    text-align: center;
  }
}
