@import 'index-mixins';

.Nav {
  display: flex;
  align-items: center;
}

.nav-item {
  margin: 0 24px 0 0;
  white-space: nowrap;

  @include maxTablet {
    margin: 0 24px 0 0;
  }

  &-link {
    color: #fff;
    @include font-small;
    font-weight: 400;
    text-decoration: none;
    opacity: 1;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.6;
    }
  }
}

.option {
  padding: 4px 16px;
  margin-bottom: 8px;

  background: #fff;
  transition: background 0.3s;

  &-group {
    margin: 16px 0;

    &-title {
      text-transform: uppercase;
      margin: 0 0 $unit * 3;
      padding: 0 $unit * 4;
      color: #888888;
      font-size: 11px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-link {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include font-base;
    text-decoration: none;
    color: #212121;
  }

  &:hover {
    background: #f7f7f7;
  }

  &:last-of-type {
    margin: 0;
  }
}

.option-link-wrapper {
  display: flex;
  flex-direction: column;
}

.option-link-title {
  display: flex;
  gap: 4px;
  align-items: center;
}

.link-description {
  @include font-small;
  color: #888888;
}
