@use "sass:math";

@import 'mixins';

$desktopColumnCount: 12;
$tabletColumnCount: 12;
$mobileColumnCount: 4;

.column {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: $gutterWidth * 0.5;
  padding-left: $gutterWidth * 0.5;

  &-desktop {
    @for $i from 0 to $desktopColumnCount + 1 {
      &-#{$i} {
        @if $i == 0 {
          display: none;
        } @else {
          display: block;
        }

        $width: math.div(100%, $desktopColumnCount) * $i;

        flex-basis: $width;
        max-width: $width;
      }
    }
  }

  &-tablet {
    @include maxTablet {
      @for $i from 0 to $tabletColumnCount + 1 {
        &-#{$i} {
          @if $i == 0 {
            display: none;
          } @else {
            display: block;
          }

          $width: math.div(100%, $tabletColumnCount) * $i;

          flex-basis: $width;
          max-width: $width;
        }
      }
    }
  }

  &-mobile {
    @include maxMobile {
      @for $i from 0 to $mobileColumnCount + 1 {
        &-#{$i} {
          @if $i == 0 {
            display: none;
          } @else {
            display: block;
          }

          $width: math.div(100%, $mobileColumnCount) * $i;

          flex-basis: $width;
          max-width: $width;
        }
      }
    }
  }
}
