@import 'index-mixins';

.Feedback {
  margin-top: 100px;

  @include maxMobile() {
    overflow: hidden;
    margin-top: 60px;
  }
}

.wrap {
  outline: none;
}

.report {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  min-height: 150px;
  padding: 16px;
  border-radius: 4px;
  letter-spacing: normal;
  background-color: #ffffff;
  white-space: normal;
  outline: none;
}

.title {
  @include GUI-font('title-2');

  font-weight: bold;
  margin: 0 0 24px;
}

.rating {
  padding: 2px 5px;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.23;
  text-align: center;
  border-radius: 2px;
  background-color: #ffd41e;
}

.ratingText {
  margin-left: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
}

.text {
  flex-grow: 1;
  padding: 8px 0 5px 0;
  font-size: 14px;
  line-height: 1.43;
}

.footer {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #9e9e9e;
}
