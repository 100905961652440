@import 'index-mixins';

.wrapper {
  background-color: $colorWhite;
}

.container {
  padding: 24px 0;
}

.title {
  @include font-base;

  font-weight: bold;

  margin-bottom: 8px;
}

.browsersList {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 16px;
  margin: 0 -8px;

  @include maxMobile {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.browserItem {
  display: block;
  width: 100%;
  height: 80px;
  min-width: 200px;
  margin: 8px;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 16px 16px 0 80px;
  background-color: $colorGreySmoke;
  text-decoration: none;
  color: $colorGreyMedium;
  font-size: 12px;
  line-height: 24px;
  background-repeat: no-repeat;
  background-position: 16px 16px;

  @include maxMobile {
    width: 300px;
  }
}

.browserTitle {
  color: $colorBlack;
  font-size: 14px;
  line-height: 24px;
}

.browserEdge {
  background-image: url(https://static.onetwotrip.com/images/partners/browsers/edge.png);
}
.browserChrome {
  background-image: url(https://static.onetwotrip.com/images/partners/browsers/chrome.png);
}
.browserFirefox {
  background-image: url(https://static.onetwotrip.com/images/partners/browsers/firefox.png);
}
.browserOpera {
  background-image: url(https://static.onetwotrip.com/images/partners/browsers/opera.png);
}
