@import 'index-mixins';

.container {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  text-decoration: none;

  &:hover {
    .cardAction {
      background-color: rgba($colorWhiteRGB, 0.3);
    }
  }
}

.containerInfo {
  composes: container;
  cursor: default;
}

.cardAction {
  transition: background-color 0.4s;
}
