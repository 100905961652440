@import 'index-mixins';

.MobileAppSMSLinkForm {
  display: flex;
}

.phoneInput {
  border-radius: 4px;
  padding-right: 30px;
  margin-right: 8px;
}

.getLinkButton {
  @include font-base;
  font-weight: bold;
  width: 186px;
  background-color: $colorBlack;
  border-color: $colorBlack;
  color: $colorWhite;

  &:hover {
    background-color: $colorBlack;
    border-color: $colorBlack;
    color: $colorWhite;
  }
}

.buttonContent {
  padding: 2px 0;
}
