@import 'index-mixins';

.MobileView {
  border-radius: $unit;
  background-color: white;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  text-decoration: none;
  display: block;
  padding: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
  color: #212121;

  @include maxTablet {
    .getLinkCaption {
      .icon {
        margin-left: 0;
      }
    }
    .title {
      white-space: normal;
      width: 63%;
    }
  }
}

.caption {
  opacity: 0.5;
  font-size: 13px;
  font-weight: normal;
  line-height: $unit * 4;
}

.caption + .title {
  margin-top: $unit;
}

.title {
  font-size: 16px;
  font-weight: bold;
  line-height: $unit * 5;
  white-space: pre-wrap;
}

.title + .getLinkCaption {
  margin-top: $unit * 4;
}

.getLinkCaption {
  font-size: 13px;
  line-height: $unit * 4;

  .icon {
    display: inline-block;
    vertical-align: top;
    width: 16px;
    height: 16px;
    margin-left: $unit * 10;
    transform: rotate(-90deg);
    background-image: url('https://static.onetwotrip.com/images/index/arrow-d-new.svg');
    background-repeat: no-repeat;
    background-position: center;
  }
}
