@import 'src/styles/index-mixins';

.container {
  @include font-large;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba($colorGreySmokeRGB, 0.1);
  color: $colorWhite;
  border: none;
  border-radius: 4px;
  text-align: center;
  padding: 0;
  box-sizing: border-box;
  transition: background-color 0.4s;

  @include mobile {
    @include font-base;
  }
}

.tooltipTrigger {
  width: 100%;
  height: 100%;
}
