@import 'index-mixins';

.Footer {
  padding: 0 16px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}

.copyright {
  max-width: 800px;
  color: $colorGreyMedium;
  @include font-micro;
  margin: auto;
  user-select: none;
}
