@import 'index-mixins';

$tabletAdaptivityScreen: 950px;

.container {
  background-color: #f7f7f7;
  position: relative;
}

.languageSwitcherContainer {
  margin-left: $unit * 6;

  &--noMargin {
    margin-left: 0;
  }
}

.content {
  min-height: 100vh;
  position: relative;
  margin-bottom: 0px;
}

.block + .block {
  margin-top: 100px;

  @include maxMobile {
    margin-top: 60px;
  }
}

.languageSwitcherDropdown {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.header {
  @include maxTablet {
    display: none;
  }
}

.mobileHeader {
  display: none;
  @include maxTablet {
    display: block;
  }
}

.hideOnMobile {
  @include maxMobile {
    display: none;
  }
}

body,
input,
button {
  @include font;
}

.footer {
  margin-top: 0px;
  padding-top: 100px;

  @include maxTablet {
    margin-top: 60px;
    padding-top: 40px;
    background-color: #fff;
  }
}
