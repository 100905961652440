@import 'index-mixins';

.title,
.subtitle,
.form,
.submit {
  @include loadingSkeleton();
}

.title,
.subtitle {
  background-color: $colorGreyLight;
}

.title {
  height: 36px;
  width: 60%;
  margin-bottom: 6px;
}

.subtitle {
  height: 20px;
  width: 80%;
  margin-bottom: 24px;
}

.form {
  width: 100%;
  height: 208px;
  background-color: $colorGreyLight;
  margin-bottom: 16px;
}

.submit {
  width: 100%;
  height: 48px;
  background-color: $colorGreyLight;
}
