@import 'index-mixins';

.relative {
  position: relative;
}

.arrows {
  display: flex;
}

.arrow {
  display: none;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  z-index: 1;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  margin-top: -40px;
  cursor: pointer;
  user-select: none;
  transition: opacity $animationDuration, background-color $animationDuration;

  &--left {
    left: -24px;
    transform: rotate(90deg);
  }

  &--right {
    right: -24px;
    transform: rotate(-90deg);
  }

  @include minDesktop {
    display: flex;
  }
}

.dots {
  &--left {
    text-align: left !important;
  }

  @include maxMobile {
    &--left {
      text-align: center !important;
    }
  }
}
