@import 'index-mixins';

.SuccessState {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  margin: 24px 0;
}

.title {
  margin: 0 0 16px 0;

  @include font-title-3;
  font-weight: bold;
  color: $colorBlack;
  user-select: none;
}

.description {
  margin: 0 0 32px 0;

  color: $colorGreyMedium;
  @include font-base;
  text-align: center;
  user-select: none;
}
