@import 'index-mixins';

.BulletedList {
}

.title {
  @include GUI-font('title-2');

  width: 100%;
  font-weight: bold;
  margin: 0 0 32px;

  @include maxMobile {
    white-space: pre-line;
  }
}
