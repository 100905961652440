@import 'index-mixins';

.container {
  background: #fff;
  padding: 36px 0;
  margin-bottom: -$unit * 16;
  display: none;
}

.sliderWrapper {
  display: block;
  @include maxTablet {
    display: none;
  }
}

.pressList {
  display: none;
  @include maxTablet {
    display: flex;
    flex-wrap: wrap;
  }
}

.caption {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.23;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #888888;
  margin: 0;
}

.item {
  text-decoration: none;
  color: #212121;
  display: inline-flex !important;
  flex-direction: column;
  outline: none;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 34px;
}

.arrows {
  display: flex;
  @include maxTablet {
    display: none;
  }
}

.arrow {
  cursor: pointer;

  &--left {
    transform: scaleX(-1);
    margin: 0 $unit * 2 0 0;
  }

  &--right {
    margin: 0 0 0 $unit * 2;
  }
}

.text {
  display: block;
  overflow: hidden;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-line;
  text-align: center;
  min-height: 70px;
}

.text::before {
  content: '“';
  display: block;
  font-family: Georgia;
  font-size: 44px;
  font-weight: normal;
  text-align: center;
  line-height: 0.82;
  color: #cccccc;
  margin-bottom: -13px;
}

.logo {
  display: block;
  margin: auto auto 0;
  width: $unit * 50.5;
  height: 110px;
  background: no-repeat center;
  background-size: contain;

  @include maxTablet {
    height: 65px;
    width: $unit * 30;
  }
  @include maxMobile {
    height: 55px;
    width: $unit * 25;
  }
}
