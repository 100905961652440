@import 'index-mixins';

.wrapper {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 40px;
  background-color: $colorWhite;
  border-radius: 24px;

  @include maxTablet {
    display: flex;
    flex-direction: column-reverse;
    padding: 24px;
  }
}

.content {
  padding-right: 40px;

  @include maxTablet {
    padding: 0;
  }
}

.title {
  font-size: 28px;
  line-height: 36px;
  font-weight: bold;
  margin-bottom: 16px;

  @include maxTablet {
    font-size: 20px;
    line-height: 26px;
  }
}

.description,
.note {
  font-size: 20px;
  line-height: 28px;
  color: $colorGreyMedium;

  @include maxTablet {
    @include font-large;
  }
}

.description {
  margin-bottom: 32px;
  padding-right: 75px;

  @include maxTablet {
    padding-right: 0;
  }
}

.note {
  @include maxTablet {
    margin-bottom: 28px;
  }
}

.button {
  position: absolute;
  bottom: 42px;
  @include font-base;
  font-weight: bold;
  background-color: $colorBlack;
  border-color: $colorBlack;
  color: $colorWhite;
  border-radius: 8px;
  width: 210px;

  &:hover {
    background-color: $colorGreyLight;
    border-color: $colorGreyLight;
    color: $colorBlack;
  }

  @include maxMobile {
    position: initial;
    width: 100%;
  }
}

.buttonContent {
  padding: 2px 0;
}

.image {
  width: 600px;
  margin: -42px -42px -42px 0;
  border-radius: 0 24px 24px 0;

  @include maxTablet {
    width: calc(100% + 48px);
    margin: -24px -24px 0 -24px;
    border-radius: 24px 24px 0 0;
    margin-bottom: 25px;
    object-fit: cover;
  }
}
