@import 'mixins';

.row {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;

  box-sizing: border-box;
  margin-left: -$gutterWidth * 0.5;
  margin-right: -$gutterWidth * 0.5;
}
