@import '../../NewOffersSlider';
$buttonRadius: 22px;

.button {
  display: none;
  position: absolute;
  top: calc(50% - $buttonRadius);
  left: -$buttonRadius;
  right: auto;
  height: 2 * $buttonRadius;
  width: 2 * $buttonRadius;
  border-radius: $buttonRadius;
  border: none;
  background-color: $colorWhite;
  cursor: pointer;
  z-index: 1;
  padding: 6px;
  @include shadow-small;

  &.nextButton {
    right: -$buttonRadius;
    left: auto;
  }

  @include min-screen($screenWithButtons) {
    display: block;
  }
}
