@import 'index-mixins';

.container {
  padding: 44px 76px;
  box-sizing: border-box;

  @include maxTablet() {
    padding: 44px 0;
  }

  @include maxMobile() {
    padding: 44px 0;
  }
}

.col {
  display: inline-flex;
  justify-content: space-around;
}
