@import 'index-mixins';

.MobileIndexHeader {
  position: relative;
  background-color: $colorBlack;
  z-index: 1;

  @include maxMobile {
    background-color: transparent;
  }
}

.productLinksContainer {
  display: none;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  flex-direction: row-reverse;
  padding: 0 16px 36px 16px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $colorBlack;
    z-index: -1;
  }

  @include maxMobile {
    display: flex !important;
  }

  &--4items {
    .productLink {
      flex-basis: 50%;
    }
  }

  &--3items,
  &--5items,
  &--6items {
    .productLink {
      flex-basis: 33%;
    }
  }

  &--7items,
  &--8items {
    .productLink {
      flex-basis: 26%;
    }
  }
}

.productLink {
  display: inline-block;
  text-align: center;
  color: white;
  font-size: 13px;
  line-height: 16px;
  margin-top: 28px;
  text-decoration: none;

  &:hover {
    .title {
      opacity: 1;
    }
  }
}

.icon {
  position: relative;

  display: inline-block;
  width: 36px;
  height: 36px;
  margin-bottom: 4px;
}

.title {
  opacity: 0.5;
  white-space: nowrap;
}

.iconBadge {
  position: absolute;

  width: 20px;
  height: 20px;

  top: -10px;
  right: -8px;
  border: 2px solid $colorBlack;
  border-radius: 50%;
}

.hotelsBadge {
  border: 4px solid $colorBlack;
}

.headerPartner-authIcon {
  fill: #01abfb;
}

.headerLogo {
  z-index: 0;
}

.headerAuth {
  position: static;
}
