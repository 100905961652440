@import 'index-mixins';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 282px;
  height: 384px;
  min-width: 282px;
  border-radius: 16px;
  background: rgba($colorBlackRGB, 0.7);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 24px;
  box-sizing: border-box;

  @include mobile {
    width: 232px;
    height: 314px;
    min-width: 232px;
    padding: 16px;
  }
}

.textWrapper {
  width: 234px;
  position: absolute;
  top: 144px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @include mobile {
    top: 118px;
    width: 200px;
  }
}

.title {
  @include font-title-3;

  color: $colorWhite;
  margin: 0;
  text-align: left;

  @include mobile {
    @include font-large;
  }
}

.description {
  @include font-base;

  color: $colorWhite;
  margin: 0;
  text-align: left;
}

.labelWrapper {
  display: flex;
}

.actionWrapper {
  width: 100%;
  min-height: 48px;

  @include mobile {
    min-height: 36px;
  }
}
