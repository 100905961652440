@import 'index-mixins';

.DesktopView {
  background: white;
  border-radius: $unit * 2;
  display: flex;
}

.content {
  padding: $unit * 8;
}

.caption {
  font-size: 12px;
  color: #888888;
  font-weight: 400;
  margin: 0;
}

.title {
  @include GUI-font('title-2');

  font-weight: bold;
  color: #212121;
  white-space: pre-wrap;
}

.caption + .title {
  margin-top: $unit * 2;
}

.imageContainer {
  flex-grow: 1;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center bottom;
  margin-top: $unit * 8;
}

.title + .getLinkCaption {
  margin-top: $unit * 8;
}

.getLinkCaption {
  font-size: 12px;
  line-height: $unit * 4;
  color: #888888;
}

.getLinkCaption + .formWrapper {
  margin: 8px 0;
}

.formWrapper {
  white-space: nowrap;
}

.mobileAppSMSLinkForm {
  margin-right: $unit * 6;
}

.storesIcons {
  display: flex;
  gap: $unit * 3;
}

.storeLink {
  display: inline-flex;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  background-color: #f7f7f7;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: 50%;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
  vertical-align: top;

  &:hover {
    background-color: rgba(33, 33, 33, 0.05);
  }
}

.app-icon {
  &--tablet {
    display: none;
  }
}

@include maxTablet {
  .app-icon {
    display: none;

    &--tablet {
      display: block;
      width: 135px;
    }
  }

  .getLinkCaption {
    visibility: hidden;
  }

  .mobileAppSMSLinkForm {
    display: none;
  }

  .storeLink {
    width: auto;
    height: auto;
    background-color: transparent;
  }
}
