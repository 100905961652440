@import 'index-mixins';

.title {
  font-size: 28px;
  line-height: normal;

  @include maxTablet {
    @include font-title-2;
  }
}

.radioButtonsWrapper {
  @include maxTablet {
    width: 100%;
  }

  & > div {
    display: flex;
  }
}

.radioButtonOption {
  height: 36px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: $colorBlack;
  @include font-base;

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
  }

  @include maxMobile {
    @include font-small;
    width: 25%;
  }
}

.links {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 24px;
  overflow: hidden;
  transition: all 0.3s;
}

.linksHidden {
  display: none;
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  @include font-large;
  color: $colorBlack;
  background-color: $colorWhite;
  padding: 12px 16px;
  border-radius: 8px;
  transition: all 0.3s;
}

.link:hover {
  background-color: $colorGreyLight;
}

.expandButton,
.minimizeButton {
  border: none;
  background: none;
  color: $colorBlue;
  @include font-base;
  cursor: pointer;
  transition: all 0.3s;
}

.expandButton:hover,
.minimizeButton:hover {
  color: $colorBlueHover;
}

.minimizeButtonPadding {
  padding-top: 8px;
}

.minimizeButtonHidden {
  display: none;
}
