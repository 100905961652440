@import 'index-mixins';

.PaymentServices {
  background: #fff;
  padding: $unit * 6 0;
  margin-bottom: -$unit * 7;

  @include maxTablet {
    display: none;
  }
}

.caption {
  font-size: 13px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #888;
  margin: 0;
  display: inline-block;
  padding-right: 24px;
}

.icons {
  vertical-align: top;
  width: 296px;
  height: 36px;
  display: inline-block;
  opacity: 0.4;
}
