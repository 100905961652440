@import 'index-mixins';

.title {
  font-size: 28px;
  line-height: 28px;
  font-weight: bold;
  margin: 0 0 24px;

  @include maxTablet {
    font-size: 21px;
  }
}

.accordion {
  position: relative;
  background-color: $colorWhite;
  border-radius: 12px;
  padding: 0 24px;
}
