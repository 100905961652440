@import 'index-mixins';

.accordionItem {
  user-select: none;

  &:last-child {
    .itemContent {
      border-bottom: none;
    }
  }
}

.itemHeader {
  display: flex;
  align-items: center;
  border-bottom: solid 1px $colorGreySmoke;
  cursor: pointer;

  @include maxMobile {
    padding-right: 50px;
  }
}

.headerTitle {
  @include font-large;
  font-weight: bold;
  margin: 24px 0;
}

.toggleButton {
  position: absolute;
  right: 24px;
  transition: transform 0.2s ease-in-out;

  @include maxMobile {
    width: 16px;
    height: 16px;
  }
}

.toggleButtonToggled {
  transform: rotate(180deg);
}

.itemContent {
  @include font-large;
  padding: 20px 0 25px 0;
  border-bottom: solid 1px $colorGreySmoke;
}
