@import 'index-mixins';

.column {
  user-select: none;
  &:focus {
    outline: none;
  }
}

.title {
  font-size: 28px;
  line-height: normal;
  font-weight: bold;
  margin: 18.76px 0;

  @include maxTablet {
    font-size: 24px;
    line-height: 32px;
  }
}

.slider {
  width: 100%;
}

.subtitle {
  min-height: 20px;
  font-size: 16px;
  line-height: normal;
  font-weight: normal;
  color: $colorGreyMedium;
  margin-bottom: 24px;

  @include maxTablet {
    padding: 0;
    margin-bottom: 36px;
  }
}

.link {
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  @include font-base;
  background-color: $colorWhite;
  color: $colorBlack;
  padding: 14px 22px;
  border-radius: 8px;
  -webkit-user-drag: none;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.5;
  }

  @include maxTablet {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include maxTablet {
    gap: 0;
  }
}

.singleList {
  margin: 0;
}

.title + .list {
  margin-top: $unit * 3;
}

.arrow {
  position: absolute;
  right: 16px;
}

:global {
  .slick-arrow > div {
    margin: 0;
  }

  .slick-list {
    margin: 0 -8px !important;
  }

  .slick-slide > div {
    padding: 0 8px;
  }
}
