@import 'mixins';

.container {
  margin-bottom: 48px;
}

.title {
  margin: 64px 0 48px;
  @include font-title-2;
  font-weight: bold;
}

.widget {
  margin-top: 48px;
}
